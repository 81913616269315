import { jsx as _jsx } from "react/jsx-runtime";
import css from "./spinner.module.css";
import classnames from "classnames";
export default function Spinner({ className, size = "M", variant = "primary" }) {
    const classes = classnames(css.loader, className, {
        [css.sizeS]: size === "S",
        [css.secondary]: variant === "secondary" || variant === "tertiary",
        [css.tertiary]: variant === "tertiary",
    });
    return _jsx("span", { className: classes });
}
