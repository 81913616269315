import conf from "../config.json";
import { target } from "msem-lib/es/services/target";

type Environment = "development" | "integration" | "uat" | "production";
type Config = {
  featureLocation: string;
  debugLodgingSearch: boolean;
  logoUrl: string;
};

const configuration = () => {
  const env = target();
  if (!env) {
    throw new Error("Target environment is not defined");
  }
  return conf[env as Environment] as Config;
};

export default configuration;
